<template>
  <Layout class="UserLinkStatistics">
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        :inline="true"
        :rules="rules"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="queryListForExport"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="handleExport"
        >
          导出
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="area"
        label="在线时长"
      />
      <el-table-column
        prop="total_connect_num"
        label="连接次数(次)"
      />
      <el-table-column
        prop="connect_rate"
        label="占比"
      />
      <el-table-column
        :label="currentTypeName"
      >
        <template v-slot="{row}">
          {{ formatTime(row.total_time) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="rate"
        label="占比"
      />
    </el-table>
  </Layout>
</template>

<script>
import { cloneDeep } from 'lodash'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { getUserLinkStatistics } from '@/api/log'
import { getYesterdaySection } from '@/utils'

export default {
  name: 'UserLinkStatistics',
  data () {
    const oneMonthCheck = (rule, value, callback) => {
      const dateRange = this.formData.dateRange
      if (!dateRange.length || !dateRange[0]) {
        return callback()
      } else {
        const start = new Date(dateRange[0]).getTime()
        const end = new Date(dateRange[1]).getTime()
        const days = (end - start) / (24 * 60 * 60 * 1000)
        if (days > 31) {
          callback(new Error('最大时间跨度为1个月'))
        } else {
          callback()
        }
      }
    }
    return {
      formData: {
        dateRange: getYesterdaySection(),
        type: 'heartbeat'
      },
      currentTypeName: '心跳总时长',
      options: [
        {
          value: 'heartbeat',
          name: '心跳时间'
        },
        {
          value: 'resource',
          name: '资源占用时间'
        }
      ],
      batchUploadTemplate: {
        list: [],
        header: ['在线时长', '连接次数(次)', '占比', '心跳总时长', '占比'],
        fields: ['area', 'total_connect_num', 'connect_rate', 'total_time', 'rate']
      },
      listInfo: {
        list: [],
        loading: false
      },
      rules: {
        dateRange: [
          { validator: oneMonthCheck }
        ]
      }
    }
  },
  mounted () {
    this.queryListForExport()
  },
  methods: {
    formatTime (seconds) {
      const timeLeft = seconds || 0
      const times = new Date(timeLeft * 1000)
      const h = Math.floor(times.getTime() / (60 * 60 * 1000))
      const H = h > 9 || h < 0 ? h : '0' + h
      const M = ('0' + times.getMinutes()).slice(-2)
      const S = ('0' + times.getSeconds()).slice(-2)
      return `${H}: ${M}: ${S}`
    },
    queryListForExport () {
      this.$refs.form.validate(valid => {
        if (valid) {
          /* eslint-disable camelcase */
          const [start_time, end_time] = this.formData.dateRange || []
          this.listInfo.loading = true
          return getUserLinkStatistics({
            start_time,
            end_time,
            type: this.formData.type
          })
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data.list
                this.currentTypeName = this.formData.type === 'heartbeat' ? '心跳总时长' : '资源占用总时长'
                return res.data.list
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    },
    handleExport () {
      this.formatToDwonload(this.listInfo.list)
      this.downloadExcel()
    },
    formatToDwonload (data) {
      const temp = cloneDeep(data)
      temp.forEach(row => {
        row.total_time = this.formatTime(row.total_time)
      })
      this.batchUploadTemplate.list = temp
      this.batchUploadTemplate.header[3] = this.currentTypeName
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      const fileName = this.formData.type === 'heartbeat' ? 'Heartbeat' : 'MachineOccupancy'
      downloadExcel(list, header, fields, fileName + '_' + new Date().toLocaleDateString())
    }
  }
}
</script>

// <style lang="less">
//  .UserLinkStatistics{

//  }
// </style>
